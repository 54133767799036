import React from 'react'
import AspectImage from '@/components/common/Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
import { STAT_GOOGLE_SEARCH_PAGE_RECENT_SEARCHES } from '@/tool/constant'
import style from './index.module.scss'
const Item = ({ app }) => {
  const { gameType, actionCode, isInstalled } = useAppLaunchParams(app)
  const onClick = (evt) => {
    onGameClickAction({
      app,
      isInstalled,
      evt,
      gameType,
      bannerLocation: STAT_GOOGLE_SEARCH_PAGE_RECENT_SEARCHES,
      actionCode,
      redirectCode: 'AppDetail',
    })
  }
  return (
    <div className={style.itemContainer}>
      <div className={style.left}>
        <AspectImage aspectClass="ratio-1-1" src={app.icon_url} onClick={onClick} />
      </div>
      <div className={style.right}>
        <span className={style.title}>{app.game_name}</span>
        <span className={style.subTitle}>{app.category}</span>
      </div>
    </div>
  )
}

export default Item
