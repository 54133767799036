import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Heading from '../common/Heading/Heading'
import AspectImage from '../common/Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import style from './advertisementSection.module.scss'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
const AdvertisementSection = ({
  app,
  titleId,
  showInstalledState,
  imgPlaceholderClass,
  bannerLocation,
}) => {
  const countryCode = useSelector((state) => state.system.countryCode)
  const { gameType, actionCode, isInstalled } = useAppLaunchParams(app)

  // 点击游戏按钮
  const handleOnClick = useCallback(
    ({ app, evt, gameType, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        gameType,
        bannerLocation,
        actionCode,
        redirectCode,
      })
    },
    [bannerLocation, isInstalled]
  )
  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!app.isAppDetailAccessable || countryCode === 'JP') {
      return false
    }
    return true
  }, [app, countryCode])

  return (
    <div
      className={classNames(['responsive-container', style.container, imgPlaceholderClass])}
      style={{
        backgroundImage: `linear-gradient(109.53deg, rgba(0, 0, 0,0.8) 0%, rgba(0, 0, 0, 0) 100%),url(${app.banner_url})`,
      }}
    >
      <div className={style.containerInner}>
        {titleId && (
          <Heading
            className={classNames(['heading', 'sectionHeading', style.adHeading])}
            titleId={titleId}
          />
        )}
        {/* 小图片 */}
        <div className={classNames(['flex', style.adSectionContent])}>
          {/* 左边 */}
          <AspectImage className={style.smallIconContainer} src={app.icon_url}>
            {/* 已安装 */}
            {showInstalledState && isInstalled && (
              <SvgIcon size={3} icon="installed" className="installed" />
            )}
            {hasDetailAction && (
              <div
                className={style.detailClickPanel}
                onClick={(evt) =>
                  handleOnClick({ app, evt, gameType, actionCode, redirectCode: 'AppDetail' })
                }
              >
                <SvgIcon size={1.5} icon="zoom-in" className={style.detailIcon} />
              </div>
            )}
          </AspectImage>
          {/* 右边 */}
          <div className={style.appDetails}>
            {!app.isOpenInBrowser && (
              <p className={style.availableText}>
                <FormattedMessage id="availableNow"></FormattedMessage>
              </p>
            )}
            <p className={style.appName}>{app.game_name}</p>
            {app.platform_name && <p className={style.platformName}>{app.platform_name}</p>}
            {/* 操作按钮 */}
            <ActionButton
              className={style.bannerAction}
              app={app}
              isInstalled={isInstalled}
              handleOnClick={handleOnClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AdvertisementSection)
