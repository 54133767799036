import React, { useImperativeHandle, useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import { getHistorys, addHistory, removeHistory, clearHistory } from './history'

import style from './historyPanel.module.scss'
import { useEffect } from 'react'
const HistoryPanel = ({ onClick, disabled = false, onChange }, ref) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  const [historys, setHistorys] = useState([])

  // Listen historys change
  useEffect(() => {
    onChange && onChange(historys)
  }, [historys, onChange])

  // Remove search history item
  const onDeleteHistory = useCallback(
    async (e, word) => {
      e.stopPropagation()
      const { list = [] } = await removeHistory(word)
      setHistorys(list)
    },
    [setHistorys]
  )

  // Clear search history
  const onClearHistory = useCallback(async () => {
    await clearHistory()
    setHistorys([])
  }, [setHistorys])

  // Get history list
  const onGetHistorys = useCallback(async () => {
    console.log(await getHistorys())
    const { list = [] } = await getHistorys()
    setHistorys(list)
  }, [])

  // Add history item
  const onAddHistory = useCallback(async (word) => {
    const { list = [] } = await addHistory(word)
    setHistorys(list)
  }, [])

  // On click item
  const onClickItem = useCallback(
    (word) => {
      // onAddHistory(word)
      onClick(word)
    },
    [onClick]
  )

  useImperativeHandle(ref, () => {
    return {
      getHistorys: onGetHistorys,
      addHistory: onAddHistory,
      clearHistory: onClearHistory,
      getClientHeight: () => containerRef.current?.clientHeight || 0,
    }
  })
  if (disabled || historys.length === 0) {
    return null
  }
  return (
    <div className={style.container} ref={containerRef}>
      <div className={style.header}>
        <SvgIcon className={style.clearIcon} size={1} icon="clock" />
        <span className={style.clearText} onClick={onClearHistory}>
          {intl.formatMessage({ id: 'clearHistory' })}
        </span>
      </div>
      <ul className={style.content}>
        {historys.map((word) => {
          return (
            <li className={style.contentItem} key={word} onClick={() => onClickItem(word)}>
              <span className={style.historyText}>{word}</span>
              <div className={style.deleteIcon}>
                <SvgIcon size={1} icon="close" onClick={(e) => onDeleteHistory(e, word)} />
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default React.forwardRef(HistoryPanel)
