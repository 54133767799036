import React, { useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PageLayout from '@/components/layout/pageLayout/Index'
import GameCarousel from '@/components/GameList/GameCarousel'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import TitleBar from '@/components/common/TitleBar/Index'
import { qwebApi } from '@/apis/qwebApi'
import { shuffleRecommendedApps } from '@/reducers/app/actions'
import { arrayShuffle } from '@/tool'
import { STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED } from '@/tool/constant'

import RecentSearch from './components/recentSearch/Index'
import Search from './components/search/Index'
import Utils from '@/Utils'
import style from './index.module.scss'
import gpbgImage from '@/images/search/gp_bg.png'
const GoogleSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  // Recommended Games
  const recommendedApps = useSelector((state) => state.app.recommendedApps)

  const state = location.state

  useEffect(() => {
    qwebApi({
      event: 'changeBannerBackgroundImage',
      data: {
        url: `${window.location.origin}${process.env.PUBLIC_URL}${gpbgImage}`, //state?.full_hd_image,
      },
    })
  }, [state])

  // Switch client search-bar
  useEffect(() => {
    qwebApi({ event: 'setSearchVisibility', data: { action: 'hide' } })
    return () => {
      qwebApi({ event: 'setSearchVisibility', data: { action: 'show' } })
    }
  }, [])

  // Go back function
  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  // Refresh recommended apps
  const onRefresh = useCallback(() => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }, [recommendedApps, dispatch])

  // Custom title render jsx
  const customTitleRender = useCallback((refreshJSX) => {
    return <TitleBar titleId={'recommendedGames'} renderRight={refreshJSX} />
  }, [])
  return (
    <PageLayout className={[style.container]} onBack={onBack}>
      <div className={style.searchContainer}>
        <Search />
      </div>
      <div className={style.content}>
        <RecentSearch className={[style.recentSearches, 'breakScreenRight']} />
        {/* Recommended For You */}
        {recommendedApps.length ? (
          <ErrorFallback>
            <GameCarousel
              slideConfig={{ spaceBetween: Utils.getResponseSise(43) }}
              className={[style.recommendedForYou, 'breakScreenRight']}
              carouselKeyId="recommendedGames"
              titleId="recommendedGames"
              bannerLocation={STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED}
              list={recommendedApps}
              // linkTo={{
              //   pathname: 'apps',
              //   search: 'type=recommendedApps&from=googleSearch',
              // }}
              customTitleRender={customTitleRender}
              onRefresh={onRefresh}
              pagination={false}
              showPlayBtn
              showCloudBtn
              imgPlaceholderClass="ratio-1-1"
              showInstalledState
              slidesPerView={6}
              isGroup={false}
            />
          </ErrorFallback>
        ) : null}
      </div>
    </PageLayout>
  )
}

export default React.memo(GoogleSearch)
