// set show app offline page type
export const SET_SHOW_APP_OFFLINE_PAGE = 'SET_SHOW_APP_OFFLINE_PAGE'

// set is online type
export const SET_IS_ONLINE = 'SET_IS_ONLINE'

// set loader type
export const SWITCH_LOADER = 'SWITCH_LOADER'

// set country code type
export const SET_APP_COUNTRY_CODE = 'SET_APP_COUNTRY_CODE'

// set language code type
export const SET_APP_LOCAL_CODE = 'SET_APP_LOCAL_CODE'
// language type
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE'

// set location type
export const SET_APP_LOCATION_PATH = 'SET_APP_LOCATION_PATH'

// set client system config type
export const SET_APP_CLIENT_SYSTEM_CONFIG = 'SET_APP_CLIENT_SYSTEM_CONFIG'

// set client mode type
export const SET_APP_CLIENT_MODE = 'SET_APP_CLIENT_MODE'
