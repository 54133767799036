import React, { useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import GameList from '@/components/GameList/Index'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import SubHeadingStrip from '@/components/common/SubHeadingStrip/Index'
import GooglePlayButtonLight from '@/components/common/Button/googlePlayLight/Index'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
import { switchLoader } from '@/reducers/system/actions'

import { STAT_SEARCH_PAGE_RESULT } from '@/tool/constant'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import style from './index.module.scss'

const SearchResults = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = new URLSearchParams(location.search)
  const query = params.get('query')
  const from = params.get('from')
  const { isFetching, data, isSuccess, isFetched } = useQuery(
    ['searchQuery', query],
    getSearchResultTransformer,
    {
      initialData: [],
      enabled: !!query,
    }
  )
  const onBack = () => {
    if (from === 'searchBar') {
      history.replace({
        pathname: 'home',
      })
    } else {
      history.goBack()
    }
  }
  const onSearchInGooglePlayClick = useCallback(() => {
    qwebApi({ event: 'onSearchInGooglePlay', data: { query } })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      event_type: STATS_POINT.BSX_SEARCH_IN_GP_CLICKED,
      query,
      banner_location: STAT_SEARCH_PAGE_RESULT,
    })
  }, [query])

  useEffect(() => {
    dispatch(switchLoader(isFetching))
  }, [isFetching, dispatch])

  return (
    <div className={style.container}>
      <GooglePlayButtonLight onClick={onSearchInGooglePlayClick} className={style.searchButton} />
      <GameList
        gameItemProps={{
          imgPlaceholderClass: 'ratio-1-1',
          showPlayBtn: true,
          showCloudBtn: true,
          showInstalledState: true,
          showTypeIcon: true,
          showPcBtn: true,
          showCsIcon: true,
          bannerLocation: STAT_SEARCH_PAGE_RESULT,
        }}
        list={data}
        contentInnerClass={['breakScreen', 'margin-bottom-30']}
        columns={6}
        customTitleRender={() => (
          <SubHeadingStrip
            className="breakScreen"
            onBack={onBack}
            style={{ paddingBottom: `${Utils.getRemSizeByResponseSise(20)}rem` }}
            title={
              <>
                <FormattedMessage id="searchResultsFor" />
                <span className="margin-left-5">
                  "{query.length > 20 ? `${query.substring(0, 20)}...` : query}"
                </span>
              </>
            }
            count={data.length}
          />
        )}
        prefix={(list) => {
          let nofoundJsx = null
          if (list.length === 0 && isFetched) {
            nofoundJsx = (
              <CommonHelpTip
                textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
                imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
                localSvg
              />
            )
          }
          return (
            <div
              className={style.helpBox}
              style={{ height: list.length === 0 ? 'calc(100% - 2px)' : 'unset' }}
            >
              {nofoundJsx}
              {/* <GooglePlayButtonLight
                onClick={onSearchInGooglePlayClick}
                className={[nofoundJsx ? 'margin-top-50' : 'margin-bottom-30']}
              /> */}
            </div>
          )
        }}
      />
    </div>
  )
}

export default React.memo(SearchResults)
