/**
 * List all topics for browse by collection
 */

import React from 'react'
import TitleBarLayout from '@/components/layout/titleBarLayout/Index'
import CollectionItem from './Item'
import bgColorList from './bgColor'
const CollectionList = ({ className, columns = 7, list = [], customTitleRender }) => {
  return (
    <TitleBarLayout
      className={className}
      customTitleRender={customTitleRender}
      columns={columns}
      list={list}
      keyId="topic_name"
    >
      {(item, index) => {
        return <CollectionItem styleObj={bgColorList[index % bgColorList.length]} topic={item} />
      }}
    </TitleBarLayout>
  )
}

export default React.memo(CollectionList)
