/**
 * Register search event for client
 */

import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { on, off, qwebApi } from '@/apis/qwebApi'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
const Hook = () => {
  const history = useHistory()

  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const [query, setQuery] = useState('')
  const { data, refetch, isFetching } = useQuery(
    ['searchQueryClient', query],
    getSearchResultTransformer,
    {
      initialData: [],
      select: (data) => data.slice(0, 12),
      enabled: !!query,
    }
  )
  const searchApps = useMemo(() => {
    return data.map((app) => {
      return {
        ...app,
        isInstalled: installedApps.includes(app.package_name),
        isAppDetailAccessable: app.isAppDetailAccessable && countryCode !== 'JP',
      }
    })
  }, [data, installedApps, countryCode])

  useEffect(() => {
    if (!isFetching) {
      qwebApi({ event: 'pushRealSearchResult', data: searchApps })
    }
  }, [searchApps, isFetching])
  // Register client search event
  useEffect(() => {
    const onClientRealSearch = (res) => {
      if (query === res.query) {
        refetch()
      } else {
        setQuery(res.query)
      }
    }
    on('clientRealSearch', onClientRealSearch)
    return () => off('clientRealSearch', onClientRealSearch)
  }, [query, refetch])

  // Notify to jump to search page by client
  useEffect(() => {
    const onClientGotoSearch = ({ query, $$from = 'searchBar' }) => {
      history.push({
        pathname: '/search',
        search: `query=${query}&from=${$$from}`,
      })
    }
    on('clientGotoSearch', onClientGotoSearch)
    return () => {
      off('clientGotoSearch', onClientGotoSearch)
    }
  }, [history])
}

export default Hook
