import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Lazy } from 'swiper'
import classNames from 'classnames'
import AdvertisementSection from './AdvertisementSection'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import style from './index.module.scss'
import Utils from '@/Utils'
import { useMemo } from 'react'
SwiperCore.use([Navigation, Lazy])
const SectionCarousel = ({
  className,
  list,
  carouselKeyId = 'carousel',
  slideConfig = {},
  slidesPerView = 1,
  slidesPerGroup,
  bannerLocation,
}) => {
  const nextElClassName = `swiper-button-next_section_${carouselKeyId}`
  const prevElClassName = `swiper-button-prev_section_${carouselKeyId}`
  const settings = useMemo(() => {
    const slidesPerViewValue =
      typeof slidesPerView === 'function' ? slidesPerView() : Number(slidesPerView)
    return {
      simulateTouch: false,
      spaceBetween: Utils.getResponseSise(20),
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1,
        elementClass: 'imageImg',
      },
      navigation: {
        nextEl: `.${nextElClassName}`,
        prevEl: `.${prevElClassName}`,
        disabledClass: style.isDisabled,
      },
      slidesPerView: slidesPerViewValue,
      slidesPerGroup: slidesPerGroup || slidesPerViewValue,
      observer: true,
      observeParents: true,
    }
  }, [slidesPerView, prevElClassName, nextElClassName, slidesPerGroup])

  const renderTitleJsx = (
    <div className={classNames([style.paginationContainer])}>
      <SvgIcon
        key="prev"
        size={1}
        icon="arrow-left-circle"
        className={[style.arrowLeft, prevElClassName]}
      />
      <SvgIcon
        key="next"
        size={1}
        icon="arrow-right-circle"
        className={[style.arrowRight, nextElClassName]}
      />
    </div>
  )
  return (
    <div
      className={classNames([style.container, className])}
      style={{ display: list.length > 1 ? '' : 'none' }}
    >
      {renderTitleJsx}
      <Swiper {...settings} {...slideConfig} className={style.swiperContainer}>
        {list.map((banner, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={index}>
              <AdvertisementSection
                app={banner}
                imgPlaceholderClass="ratio-131-50"
                titleId="featuredGame"
                showInstalledState
                bannerLocation={bannerLocation}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default React.memo(SectionCarousel)
