/**
 * Only used by home banner(deprecated)
 */

import React, { useRef } from 'react'
import HomeBannerListLayout from '@/components/GameList/HomeBannerListLayout'
import GameListItem from '@/components/common/GameListItem/GameListItem'
const HomeBannerList = ({
  showFreshIcon,
  carouselKeyId = 'common',
  // 是否自定义Title
  customTitleRender,
  list,
  // 栏目class
  className,
  contentClass,
  // TitleBarLayout组件props
  // 是否换行显示
  wrap,
  wrapInOneLine,
  moveType,
  listAppend,
  prefixAppend,
  // 一行显示数据个数计算函数
  numberOfItems = 4,
  gutter,
  // 显示几行（在wrap为false时有效）
  lineNumber,
  // GameListItem组件props
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  onRemoveClick,
  onItemClick,
  onRootClick,
  onHoverEnter,
  showInstalledState,
  showLikeIncon,
  imgPlaceholderClass,
  imageUrlGet,
  itemClassName = 'buttonVertical',
  hasHoverAction,
  hoverImageUrlGet,
  // 模块位置（用于记录打点）
  bannerLocation,
  // Image组件props
  lazyRootMargin,
  lazyRoot = () => document.querySelector('.contentInner'),
  onImageLoad,
  onImageError,
  scrollContainer,
  // layout
  layoutRootMargin,
  layoutRoot,
  // 动画相关
  mainAniStyle,
  inStyle,
  outStyle,
  inClass,
  outClass,
  bootGameOnClick,
  showCsIcon = true,
}) => {
  const titleBarLayoutRef = useRef(null)

  const layoutRootFinally = wrapInOneLine ? titleBarLayoutRef : layoutRoot || lazyRoot
  const lazyRootFinally = wrapInOneLine ? titleBarLayoutRef : lazyRoot || layoutRoot
  return (
    <HomeBannerListLayout
      ref={titleBarLayoutRef}
      className={[`${carouselKeyId}Container`, className]}
      root={layoutRootFinally}
      rootMargin={layoutRootMargin}
      contentClass={['gameList', contentClass]}
      numberOfItems={numberOfItems}
      gutter={gutter}
      list={list}
      wrap={wrap}
      wrapInOneLine={wrapInOneLine}
      moveType={moveType}
      lineNumber={lineNumber}
      isPuzzle={showFreshIcon}
      keyid={(item, index) => `${item.package_name}-${index}`}
      mainAniStyle={mainAniStyle}
      inStyle={inStyle}
      outStyle={outStyle}
      inClass={inClass}
      outClass={outClass}
      scrollContainer={scrollContainer}
      listAppend={listAppend}
      prefixAppend={prefixAppend}
      customTitleRender={customTitleRender}
    >
      {(item, index, { ref }) => {
        return (
          <GameListItem
            imageUrlGet={imageUrlGet}
            onClick={onItemClick}
            onRootClick={onRootClick}
            onRemoveClick={onRemoveClick}
            showDeleteBtn={showDeleteBtn}
            showPlayBtn={showPlayBtn}
            showCloudBtn={showCloudBtn}
            showPcBtn={showPcBtn}
            showLikeIncon={showLikeIncon}
            showTypeIcon={showTypeIcon}
            lazyRoot={lazyRootFinally}
            showCsIcon={showCsIcon}
            lazyRootMargin={lazyRootMargin}
            onImageLoad={onImageLoad}
            onImageError={onImageError}
            showInstalledState={showInstalledState}
            bannerLocation={bannerLocation}
            nameClass={`font18 bold`}
            className={itemClassName}
            categoryClass="font16 opaqueWhiteText"
            imgPlaceholderClass={imgPlaceholderClass}
            game={item}
            bootGameOnClick={bootGameOnClick}
            hasHoverAction={hasHoverAction}
            hoverImageUrlGet={hoverImageUrlGet}
            onHoverEnter={onHoverEnter && (() => onHoverEnter(item, ref, index))}
          />
        )
      }}
    </HomeBannerListLayout>
  )
}

export default React.memo(HomeBannerList)
