import React, { useRef, useMemo } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

import useScrollBarActive from '@/hook/useScrollBarActive'

import './index.scss'
const PageLayout = ({
  onScroll,
  children,
  onBack,
  renderHeader,
  titleId = 'goBack',
  className,
}) => {
  const contentRef = useRef(null)
  useScrollBarActive(contentRef, onScroll)

  // 默认头
  const headerJSX = useMemo(() => {
    const _onBack = onBack || function () {}
    return (
      <div
        className={classNames(['heading', 'sectionHeading', 'defaultHeaderWrap'])}
        onClick={_onBack}
      >
        <SvgIcon className="headerIcon" size={1} icon="go-back" />
        <FormattedMessage id={titleId} />
      </div>
    )
  }, [onBack, titleId])
  return (
    <div className={classNames(['pageLayout', className])}>
      <div className="pageLayoutHeader">{renderHeader ? renderHeader() : headerJSX}</div>
      <div className="pageLayoutContent" ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default PageLayout
