import React, { useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Switch, Route, useHistory } from 'react-router-dom'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
import { removeTrackId } from '@/tool/track'
import './index.css'
const ANIMATION_MAP = {
  PUSH: 'translateX',
  POP: 'reTranslateX',
  REPLACE: 'reTranslateX',
}
function Animate({ children }) {
  const history = useHistory()
  useEffect(() => {
    history.listen(({ pathname }) => {
      // When enter the page that pathname is same as paths below, remove trackId and re-generate trackId for stat.
      if (['/gameCenter', '/home', '/search'].includes(pathname)) {
        removeTrackId()
      }
      qwebApi({ event: 'changeBannerBackgroundImage' })
    })
  }, [])
  return (
    <Route
      render={({ location, history }) => (
        <TransitionGroup
          className="contentInner"
          childFactory={(child) => {
            const animation = Utils.getParamByName('animation')
            let classNames = ANIMATION_MAP[history.action]
            if (animation && history.action === 'PUSH') {
              classNames = animation
            }
            return React.cloneElement(child, {
              classNames,
            })
          }}
        >
          <CSSTransition timeout={200} key={location.pathname}>
            <Switch location={location}>{children}</Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    ></Route>
  )
}

export default Animate
