import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'
import Rate from 'rc-rate'
import 'rc-rate/assets/index.css'
import { useMount, useUnmount } from 'ahooks'
import ActionButton from '@/components/actionButton/Index'
import AspectImage from '@/components/common/Image/AspectImage'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import style from './banner.module.scss'
const Banner = ({
  className,
  bannerGame,
  appOverview,
  isInstalled,
  handleOnClick,
  onVisibleChange,
  simpleMode = false,
}) => {
  const observerRef = useRef(null)
  const containerRef = useRef(null)

  useMount(() => {
    if (containerRef.current) {
      observerRef.current = new IntersectionObserver((entried) => {
        try {
          onVisibleChange(entried[0].isIntersecting)
        } catch (error) {}
      })
      observerRef.current.observe(containerRef.current)
    }
  })

  useUnmount(() => {
    if (observerRef.current) {
      // observerRef.current.unobserve(containerRef.current)
      observerRef.current.disconnect()
    }
  })

  const describtionJSX = useMemo(() => {
    const jsxs = []
    if (appOverview.genre_slug) {
      jsxs.push(<span key="genreSlug">{appOverview.genre_slug}</span>)
    }
    if (appOverview.developer_name) {
      jsxs.push(<span key="developerName">{appOverview.developer_name}</span>)
    }
    if (jsxs.length === 2) {
      jsxs.splice(1, 0, <span key="split" className={style.split}></span>)
    }
    if (jsxs.length) {
      return <div className={style.describtion}>{jsxs}</div>
    } else {
      return null
    }
  }, [appOverview.genre_slug, appOverview.developer_name])

  return (
    <div className={classNames([style.container, className])} ref={containerRef}>
      <AspectImage className={style.left} src={appOverview.icon_url} alt="appPageBannerImage">
        {/* 已安装 */}
        {isInstalled && <SvgIcon size={3} icon="installed" className="installed" />}
      </AspectImage>
      <div className={style.right}>
        <h2 className={classNames([style.title, { [style.doubleLineTitle]: simpleMode }])}>
          {appOverview.app_name}
        </h2>
        {describtionJSX}
        {appOverview.app_rating ? (
          <Rate
            disabled
            defaultValue={appOverview.app_rating}
            style={{ fontSize: 18 }}
            allowHalf
            allowClear={false}
          />
        ) : null}
        <ActionButton
          className={style.actionButton}
          app={bannerGame}
          isInstalled={isInstalled}
          type="viewall"
          handleOnClick={handleOnClick}
        />
      </div>
    </div>
  )
}

export default React.memo(Banner)
