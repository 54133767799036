import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import GameCarousel from '@/components/GameList/GameCarousel'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import { useParams, useLocation } from 'react-router-dom'
import { getAppDetail } from '@/apis/detail'
import { qwebApi } from '@/apis/qwebApi'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import style from './index.module.scss'

import {
  STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES,
  STAT_APP_DETAIL_PAGE_SIMILAR_GAMES,
  STAT_APP_DETAIL_PAGE_BANNER,
} from '@/tool/constant'
import AspectImage from '@/components/common/Image/AspectImage'
import Banner from './components/banner/Banner'
import Describtion from './components/describtion/Describtion'
import GameFeature from './components/gameFeature/GameFeature'
import Gallery from '@/components/singleItem/Gallery'
import { switchLoader } from '@/reducers/system/actions'
import { setAppDetail } from '@/reducers/appdetail/actions'
import { useHistory } from 'react-router-dom'
import { popAppStack } from '@/reducers/appdetail/actions'
import { onGameClickAction } from '@/actions/commonActions'
import { isYoutubeUrl, transformToEmbedUrl } from '@/tool/youtube'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import SplitLine from '@/components/common/splitLine/Index'
import PageLayout from '@/components/layout/pageLayout/Index'

const AppDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const [isBannerVisible, setIsBannerVisible] = useState(true)

  const appDetail = useSelector((state) => state.appdetail.map[params.packageName])
  const appStacks = useSelector((state) => state.appdetail.appHeaps)

  // 当前查看app
  const bannerGame = useMemo(() => {
    return appStacks[appStacks.length - 1]
  }, [appStacks])

  const installedApps = useSelector((state) => state.app.installedApps)
  const isInstalled = installedApps.includes(bannerGame?.package_name)
  // 请求app详情
  const { isFetching, isSuccess, isFetched } = useQuery(
    ['getAppDetail', params.packageName],
    () => getAppDetail({ packageName: params.packageName }),
    {
      enabled: Boolean(!appDetail),
      onSuccess({ data }) {
        if (data) {
          dispatch(setAppDetail({ key: params.packageName, value: data }))
        } else {
          if (bannerGame) {
            dispatch(
              setAppDetail({
                key: params.packageName,
                value: {
                  game_overview: {
                    icon_url: bannerGame.icon_url,
                    app_name: bannerGame.game_name,
                    app_banner_url: bannerGame.full_hd_image,
                  },
                  similar_apps: [],
                  similar_category_games: [],
                },
              })
            )
          }
        }
      },
    }
  )

  // 初始化
  useEffect(() => {
    if (appDetail) {
      const { game_overview = {}, gallery = {} } = appDetail
      const { nowgg_videos } = gallery
      // setTimeout(() => {
      qwebApi({
        event: 'changeBannerBackgroundImage',
        data: {
          url: game_overview.app_banner_url,
          video: nowgg_videos?.desktop[0] || '',
          sleep: 1000,
        },
      })
      // }, 0)
    }
  }, [appDetail, location.state])

  // loading事件
  useEffect(() => {
    dispatch(switchLoader(isFetching))
  }, [isFetching, dispatch])

  // 游戏截图及视频资源
  const screenshotsAndVideos = useMemo(() => {
    if (appDetail) {
      const { gp_videos = [], screenshots = [] } = appDetail.gallery || {}
      const gpVideos = gp_videos.map((video) => {
        const url = isYoutubeUrl(video) ? transformToEmbedUrl(video) : video
        return {
          src: url,
          video: url,
          type: 1,
        }
      })

      const screenshotsImages = screenshots.map((shot) => {
        return {
          src: shot.thumb,
          ...shot,
          type: 2,
        }
      })
      return [...gpVideos, ...screenshotsImages]
    }
    return []
  }, [appDetail])

  // 返回
  const _onGotoBack = useCallback(() => {
    // 说明由searchBar进入appdetail页面
    if (location.state?.from === 'searchBar') {
      sessionStorage.setItem('BANNER_STATUS_home', 'header')
      history.replace({
        pathname: '/home',
      })
    } else {
      history.goBack()
    }
    setTimeout(() => {
      dispatch(popAppStack())
    }, 500)
  }, [history, dispatch, location.state])

  // gallery点击事件
  const _onGalleryClick = useCallback(
    (index) => {
      qwebApi({
        event: 'onOpenGallery',
        data: {
          gallery: screenshotsAndVideos,
          index,
        },
      })
    },
    [screenshotsAndVideos]
  )

  // 滚动事件
  const _onContainerScroll = useCallback((e) => {
    qwebApi({
      event: 'onPageScroll',
      data: {
        x: parseInt(e.target.scrollLeft),
        y: parseInt(e.target.scrollTop),
      },
    })
  }, [])

  // 点击项目(appDetail 无需加actionCode)
  const handleOnClick = useCallback(
    ({ evt, gameType, app }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        gameType,
        bannerLocation: STAT_APP_DETAIL_PAGE_BANNER,
        redirectCode: 'AppLauncher',
      })
    },
    [isInstalled]
  )

  const onVisibleChange = useCallback((visible) => {
    setIsBannerVisible(visible)
  }, [])

  const renderHeader = useCallback(() => {
    const showBannerExtra = !isBannerVisible && appDetail
    return (
      <div className={style.header}>
        <div
          className={classNames(['heading', 'sectionHeading', style.headerLeft])}
          onClick={_onGotoBack}
        >
          <div className="headerIconContainer">
            <SvgIcon className={style.headerBackIcon} size={1} icon="go-back" />
          </div>
          {showBannerExtra && (
            <AspectImage
              className={style.headerGameIcon}
              src={appDetail.game_overview?.icon_url}
              alt="appPageBannerImage"
            />
          )}
          <FormattedMessage id="goBack" />
        </div>
        {showBannerExtra && (
          <ActionButton
            className={style.actionButton}
            app={bannerGame}
            isInstalled={isInstalled}
            type="viewall"
            size={'small'}
            handleOnClick={handleOnClick}
          />
        )}
      </div>
    )
  }, [isBannerVisible, handleOnClick, appDetail, _onGotoBack, bannerGame, isInstalled])

  // if (isFetching) {
  //   return null
  // }

  return (
    <PageLayout
      onScroll={_onContainerScroll}
      className={style.container}
      renderHeader={renderHeader}
    >
      {!appDetail && isFetched ? (
        <CommonHelpTip
          className={style.errorDisplay}
          textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
          imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
          localSvg
        />
      ) : appDetail ? (
        <>
          <div className={style.contentWrap}>
            {/* banner */}
            <ErrorFallback>
              <Banner
                packageName={params.packageName}
                className={style.banner}
                bannerGame={bannerGame}
                isInstalled={isInstalled}
                appOverview={appDetail.game_overview}
                handleOnClick={handleOnClick}
                bannerLocation={STAT_APP_DETAIL_PAGE_BANNER}
                onVisibleChange={onVisibleChange}
                simpleMode={appDetail.game_overview.app_rating === undefined}
              />
            </ErrorFallback>
            {/* screenshots & video */}
            {screenshotsAndVideos.length > 0 ? (
              <ErrorFallback>
                <GameCarousel
                  className={style.screenshots}
                  carouselKeyId="appDetailScreenshotsAndVideo"
                  titleClass={classNames(['spaceBetween', style.screenshotsTitle])}
                  titleId="screenshotsAndVideo"
                  list={screenshotsAndVideos}
                  slidesPerView={4}
                  isGroup={false}
                  refresh={false}
                  slideConfig={{simulateTouch:true}}
                  renderItem={({ slide, index }) => {
                    return (
                      <Gallery
                        className="screenshotsGallery"
                        imgPlaceholderClass="ratio-73-40"
                        gallery={slide}
                        type={slide.type}
                        isEmbedSrc={slide.src.includes('youtube')}
                      >
                        <div
                          className={style.galleryMask}
                          onClick={() => _onGalleryClick(index)}
                        ></div>
                      </Gallery>
                    )
                  }}
                />
              </ErrorFallback>
            ) : null}
            {/* app describtion */}
            {appDetail.game_overview &&
            (appDetail.game_overview.game_description ||
              appDetail.game_overview.google_play_description) ? (
              <ErrorFallback>
                <SplitLine />
                <Describtion describtion={appDetail.game_overview} className={style.describtion} />
              </ErrorFallback>
            ) : null}

            {/* feature apps */}
            {appDetail.game_features && Object.keys(appDetail.game_features).length > 0 ? (
              <ErrorFallback>
                <SplitLine />
                <GameFeature features={appDetail.game_features} className={style.gameFeature} />
              </ErrorFallback>
            ) : null}

            {/* more strategy games */}
            {appDetail.similar_category_games.length > 0 &&
            appDetail.similar_category_games_meta?.title ? (
              <ErrorFallback>
                <SplitLine />
                <GameCarousel
                  className={style.moreStrategyGames}
                  carouselKeyId="appDetailMoreStrategyGames"
                  titleClass="spaceBetween"
                  title={appDetail.similar_category_games_meta.title}
                  imgPlaceholderClass="ratio-1-1"
                  bannerLocation={STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES}
                  linkTo={{
                    pathname: '/apps',
                    search: `type=moreStrategyGames&title=${appDetail.similar_category_games_meta.title}&from=appDetail&apk=${params.packageName}`,
                  }}
                  showCloudBtn
                  showPlayBtn
                  list={appDetail.similar_category_games}
                  slidesPerView={5}
                  isGroup={false}
                  refresh={false}
                />
              </ErrorFallback>
            ) : null}

            {/* similar games */}
            {appDetail.similar_apps.length > 0 && appDetail.similar_apps_meta?.title ? (
              <ErrorFallback>
                <SplitLine />
                <GameCarousel
                  className={style.similarGames}
                  carouselKeyId="appDetailSimilarGames"
                  titleClass="spaceBetween"
                  title={appDetail.similar_apps_meta.title}
                  imgPlaceholderClass="ratio-1-1"
                  bannerLocation={STAT_APP_DETAIL_PAGE_SIMILAR_GAMES}
                  linkTo={{
                    pathname: '/apps',
                    search: `type=similarGames&title=${appDetail.similar_apps_meta.title}&from=appDetail&apk=${params.packageName}`,
                  }}
                  showCloudBtn
                  showPlayBtn
                  list={appDetail.similar_apps}
                  slidesPerView={5}
                  isGroup={false}
                  refresh={false}
                />
              </ErrorFallback>
            ) : null}
          </div>
        </>
      ) : null}
    </PageLayout>
  )
}

export default React.memo(AppDetail)
