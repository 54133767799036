import { cloudGanmeAppsTransformer } from '@/transformers'
import * as types from './constant'
import { getCloudGameApps } from '@/apis/apps'
import { switchLoader } from '../system/actions'

// Fetch cloud game apps action
export const fetchCloudGameApps = ({ showLoading, locale, showDisconnect }) => {
  return async (dispatch, getState) => {
    const localeCode = locale || getState().system.localeCode
    if (showLoading) {
      dispatch(switchLoader())
    }
    try {
      const _result = await getCloudGameApps({ showDisconnect, locale: localeCode })
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: cloudGanmeAppsTransformer(_result),
      })
    } catch (error) {
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: {
          cloudGames: [],
          isFetchSuccess: false,
        },
      })
    }
    if (showLoading) {
      dispatch(switchLoader(false))
    }
  }
}
