/**
 * Game center browse by collection module
 */

import React from 'react'
import { useSelector } from 'react-redux'
import CarouselLayout from '@/components/Carousel/CarouselLayout'
import CollectionItem from './Item'
import bgColorList from './bgColor'

const Collections = ({ className, titleClass, titleId, path = 'collections', columns = 7 }) => {
  const topics = useSelector((state) => state.app.topics)
  return (
    <CarouselLayout
      carouselKeyId={titleId}
      list={topics}
      className={className}
      titleId={titleId}
      titleClass={titleClass}
      linkTo={{
        pathname: path,
      }}
      slidesPerView={columns}
      upOnePage={topics.length > columns}
      keyId="topic_name"
    >
      {(item, index) => {
        return <CollectionItem topic={item} styleObj={bgColorList[index % bgColorList.length]} />
      }}
    </CarouselLayout>
  )
}

export default React.memo(Collections)
