/**
 *
 */
import React, { useMemo, useRef } from 'react'
import classnames from 'classnames'
import Scrollload from '@/components/scrollload/Scrollload'
import useScrollBarActive from '@/hook/useScrollBarActive'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'

import style from './index.module.scss'
const TitleBarLayout = ({
  className,
  contentInnerClass,
  columns,
  list,
  customTitleRender,
  children,
  keyId,
  suffix = null,
  prefix = null,
  // scrollKey = 'titleBarLayout',
  // scrollMemoryEnable = true,
}) => {
  const scrollContainerRef = useRef(null)
  // 标题栏
  const titleBarHeaderJsx = useMemo(() => {
    let titleJSX = null
    if (customTitleRender) {
      titleJSX = typeof customTitleRender === 'function' ? customTitleRender() : customTitleRender
    }
    return titleJSX
  }, [customTitleRender])

  // 列表样式
  const contentInnerStyl = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${columns}, minmax(10px, 1fr))`,
    }
  }, [columns])
  useScrollBarActive(scrollContainerRef)

  return (
    <div className={classnames([style.container, className])}>
      <div className={style.header}>{titleBarHeaderJsx}</div>
      <div className={style.content} ref={scrollContainerRef}>
        <div className={style.prefix}>{prefix && prefix(list)}</div>
        <div
          className={classnames([style.contentInner, contentInnerClass])}
          style={contentInnerStyl}
        >
          <ErrorFallback>
            <Scrollload
              list={list}
              scrollContainer={() => scrollContainerRef.current}
              numberOfItems={columns}
              render={(scrollList) => {
                return scrollList.map((item, index) => {
                  return (
                    <div className={style.contentItem} key={(item[keyId]+'_'+index) || index}>
                      {children(item, index)}
                    </div>
                  )
                })
              }}
            />
          </ErrorFallback>
        </div>
        <div className={style.suffix}>{suffix && suffix(list)}</div>
      </div>
    </div>
  )
}

export default React.memo(TitleBarLayout)
