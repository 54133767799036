import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import Button from '@/components/common/Button/Button'

// actions
import { setInstalledApps, setLocalPerference } from '@/reducers/app/actions'
import { setClientUserConfig, setUserInfo } from '@/reducers/user/actions'
import { setPageLocation, setIsOffline, setClientMode } from '@/reducers/system/actions'
import { pushAppStack } from '@/reducers/appdetail/actions'

import useInstalledApps from '@/hook/useInstalledApps'
import usePerference from '@/hook/usePerference'
import useUserToken from '@/hook/useUserToken'
import useClientUserConfig from '@/hook/useClientUserConfig'
import useGmQuery from '@/hook/useGmQueryRemote'
import useNetwork from '@/hook/useNetwork'
import useGotoDetailForClient from '@/hook/useGotoDetailForClient'
import useCloudMode from '@/hook/useCloudMode'
import useRouter from '@/hook/useRouter'
import { createTrackId } from '@/tool/track'

import { on, off, qwebApi } from '@/apis/qwebApi'
import { useRef } from 'react'

// Page I18N Mapping(default:page)
const PageIntlMap = {
  gameCenter: 'androidGames',
  home: 'home',
  cloudGame: 'cloudGame',
}
const PageIntlMapProxy = new Proxy(PageIntlMap, {
  get(obj, key) {
    return obj[key] || 'page'
  },
})

const Setup = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const pageLocation = useSelector((state) => state.system.location)
  // const loader = useSelector((state) => state.system.loader)

  const pageLocationRef = useRef(pageLocation)
  useEffect(() => {
    pageLocationRef.current = pageLocation
  }, [pageLocation])
  // Search logic between client and web
  useGmQuery()

  // Register Router event
  useRouter()

  // Global loader(client loading)
  // useEffect(() => {
  //   if (loader) {
  //     qwebApi({ event: 'openClientLoading' })
  //   } else {
  //     qwebApi({ event: 'closeClientLoading' })
  //   }
  // }, [loader])
  // Jump to app detail
  const onOpenDetail = useCallback(
    (appDetail) => {
      if (appDetail) {
        // $$isClear:whether to clear appStack，default true;$$from:from where,default searchBar(client search bar)
        const { $$isClear = true, $$from = 'searchBar', ...app } = appDetail
        dispatch(pushAppStack({ ...app, $$isClear }))
        if ($$from === 'searchBar') {
          // Page location redirect to home
          createTrackId()
          dispatch(setPageLocation('home'))
        }
        history.push({
          pathname: `/app/${appDetail.package_name}`,
          state: {
            from: $$from,
          },
        })
      }
    },
    [history, dispatch]
  )

  useGotoDetailForClient(onOpenDetail)

  // local perference
  const localPerference = usePerference()
  useEffect(() => {
    dispatch(setLocalPerference(localPerference))
  }, [localPerference, dispatch])

  // client config (one_button_config)
  const clientUserConfig = useClientUserConfig()
  useEffect(() => {
    dispatch(setClientUserConfig(clientUserConfig))
  }, [dispatch, clientUserConfig])

  // User info
  const useInfo = useUserToken()
  useEffect(() => {
    dispatch(setUserInfo(useInfo))
  }, [useInfo, dispatch])

  // Update installed app(install and uninstall)---only package name
  const installedApps = useInstalledApps()
  useEffect(() => {
    dispatch(setInstalledApps(installedApps))
  }, [installedApps, dispatch])

  // bsx mode
  const { mode } = useCloudMode()
  useEffect(() => {
    if (mode) {
      dispatch(setClientMode(mode))
      window.__CLIENT_CONFIG__.commonConfig.cloud_mode = mode === 'cloud'
      const redirectPath = `/${pageLocationRef.current}`
      if (window.location.pathname !== redirectPath) {
        qwebApi({ event: 'closeClientLoading' })
        history.push({
          pathname: redirectPath,
          search: 'animation=fade',
        })
      }
    }
  }, [mode, dispatch, history])

  // Page router event register for client
  useEffect(() => {
    const onRouterTo = ({ path }) => {
      if (pageLocationRef.current === path) {
        return
      }
      dispatch(setPageLocation(path))
      qwebApi({ event: 'closeClientLoading' })
      history.push({
        pathname: `/${path}`,
        search: 'animation=fade',
      })
    }
    on('pageRouterTo', onRouterTo)
    return () => {
      off('pageRouterTo', onRouterTo)
    }
  }, [dispatch, history])

  // Get whether is online and last time status
  const [online, prevOnline] = useNetwork()
  useEffect(() => {
    dispatch(setIsOffline(online))
  }, [online, dispatch])

  // Whether to show offline page
  const showOfflinePage = useSelector((state) => state.system.showOfflinePage)
  useEffect(() => {
    if (showOfflinePage) {
      qwebApi({ event: 'changeBannerBackgroundImage' })
    }
  }, [showOfflinePage])

  if (online && !prevOnline) {
    setTimeout(() => {
      window.location.href = `/${pageLocation}`
    }, 5000)
  }
  if (showOfflinePage) {
    // Notify to refresh page
    const onRefreshClick = () => {
      window.location.href = `/${pageLocation}`
    }
    return (
      <CommonHelpTip
        textTitle="noInternetConnect"
        textValue="offlineMessageBySearch"
        className="appNoNetworkContainer"
        imgSrc="noNetwork"
        localSvg={true}
        values={{
          page: <FormattedMessage id={PageIntlMapProxy[pageLocation]} />,
        }}
        extra={() => (
          <Button
            type="primary"
            className="margin-top-20 font16 minw200"
            onClick={onRefreshClick}
            titleId="refresh"
          />
        )}
      />
    )
  }
  return null
}

export default Setup
