import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClientProvider, QueryClient } from 'react-query'
import store from './store'
import './sass/index.scss'
import App from './App'
import Account from './pages/status/Account'
import LocaleProvider from './components/LocaleProvider'
import qwebchannel from '@/tool/qwebchannel'
import { qwebApi, fetchQwebApi } from '@/apis/qwebApi'
import defaultHostConfig from '@/tool/defaultHostConfig'
import './assets/icons'
import '@/assets/fontsjs'
import 'swiper/css/bundle'
import logger from '@/tool/logger'
import { CLIENT_MODE_KEY } from '@/reducers/system/reducer'

const root = document.getElementById('root')
// Merge host config to window.__HOST_CONFIG__
function setHostConfig(clientHostConfig = {}) {
  const config = {}
  Object.keys(clientHostConfig).forEach((key) => {
    if (clientHostConfig[key]) {
      config[key] = clientHostConfig[key]
    }
  })
  Object.defineProperty(window, '__HOST_CONFIG__', {
    writable: false,
    enumerable: false,
    value: Object.freeze({ ...defaultHostConfig, ...config }),
  })
}

// Merge client system config to window.__CLIENT_CONFIG__
function setclientSystemConfig(config = {}) {
  Object.defineProperty(window, '__CLIENT_CONFIG__', {
    enumerable: false,
    value: Object.freeze({
      systemConfig: {},
      commonConfig: {
        source: 'bstx',
        oem: 'nxt',
        bsxversion: config.x_version,
        launcher_guid: config.launcher_guid,
        cloud_mode: window.localStorage.getItem(CLIENT_MODE_KEY) === 'cloud',
      },
      ...(config || {}),
    }),
  })
}
// Run on the browser
if (window.location.pathname === '/account') {
  setHostConfig()
  createRoot(root).render(
    <Provider store={store}>
      <LocaleProvider>
        <Account />
      </LocaleProvider>
    </Provider>
  )
} else {
  // Wait for webchannel initialization...then can communicate with client using webchannel API
  qwebchannel().then(async () => {
    logger('finish', 'qwebChannelConnectCompleted')
    // Record the web version
    qwebApi({
      event: 'postWebVersionInfo',
      data: {
        stamp: process.env.REACT_APP_STAMP,
        version: process.env.REACT_APP_VERSION,
        name: 'BlueStacks X Version',
      },
    })
    // Instantiate Query client
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          retry: 0,
          staleTime: 0,
          cacheTime: 0,
        },
      },
    })
    logger('start', 'preClientConfigFetch')
    // Fetch host config
    const clientHostConfig = await fetchQwebApi({ event: 'getHostConfig' }, { initial: {} })
    // Fetch client system config
    const clientBaseConfig = await fetchQwebApi(
      { event: 'getClientConfig' },
      { initial: { x_version: '0.16.1.0' } }
    )
    setHostConfig(clientHostConfig)
    setclientSystemConfig(clientBaseConfig)
    logger('finish', 'preClientConfigFetch')
    createRoot(root).render(
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <LocaleProvider>
            <App />
          </LocaleProvider>
        </QueryClientProvider>
      </Provider>
    )
  })
}
