import * as types from './constant'
import { fetchCloudGameApps } from '../cloud/actions'
import {
  rotationDataTransformer,
  recommendedAppsTransformer,
  sidepanelDataTransformer,
  featureAppsDataTransformer,
  topicAppsDataTransformer,
  suggestedAppsTransformer,
} from '@/transformers'
import {
  getRotationAppsApi,
  getHomeAppsApi,
  getSidepanelAppsApi,
  getFeatureAppsApi,
  getSuggestedApps,
} from '@/apis/apps'

import { errorLog } from '@/tool'
import { switchLoader } from '../system/actions'

import { qwebApi } from '@/apis/qwebApi'

import logger from '@/tool/logger'

// Set installed apps action
export const setInstalledApps = (apps) => {
  return {
    type: types.SET_INSTALLED_APPS,
    payload: apps,
  }
}

// Fetch suggested apps action
export const fetchSuggestedApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchSuggestedApps')
    try {
      const _result = await getSuggestedApps()
      dispatch({
        type: types.FETCH_SUGGESTED_APPS,
        payload: suggestedAppsTransformer(_result?.apps || []),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchSuggestedApps')
  }
}

// Fetch appcenter/game_center_rotation action
export const fetchRotationApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchRotationApps')
    try {
      const _result = await getRotationAppsApi()
      dispatch({
        type: types.FETCH_ROTATION_APPS,
        payload: rotationDataTransformer(_result.rotation),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchRotationApps')
  }
}

// Fetch appcenter/game_center_home action
export const fetchGameCenterApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchGameCenterApps')
    try {
      const _result = await getHomeAppsApi()
      dispatch({
        type: types.FETCH_GAME_CENTER_HOME_APPS,
        payload: recommendedAppsTransformer(_result),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchGameCenterApps')
  }
}

// Shuffle recommended apps action
export const shuffleRecommendedApps = (apps) => {
  return {
    type: types.SHUFFLE_RECOMMENDED_APPS,
    payload: apps,
  }
}

// Fetch feature apps action
export const fetchFeaturedApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchFeatureApps')
    try {
      const _result = await getFeatureAppsApi()
      dispatch({
        type: types.FETCH_FEATURED_APPS,
        payload: featureAppsDataTransformer(_result),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchFeatureApps')
  }
}

// Set topic map action
export const setTopicApps = ({ data, topicId }) => {
  const apps = topicAppsDataTransformer(data.topic_game || [])
  return {
    type: types.SET_TOPIC_APPS,
    payload: { apps, topicId, info: data.topic },
  }
}

// Fetch side_panel action
export const fetchSidepanelApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchSidepanelApps')
    try {
      const _result = await getSidepanelAppsApi()
      return dispatch({
        type: types.FETCH_SIDEPANEL_APPS,
        payload: sidepanelDataTransformer(_result.data),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchSidepanelApps')
  }
}

// Initialize app action
export const initGameCenterApps = () => {
  return async (dispatch) => {
    dispatch(switchLoader())
    logger('start', 'preCoreDataLoad')
    try {
      dispatch(fetchFeaturedApps())
      dispatch(fetchSuggestedApps())
      await Promise.all([dispatch(fetchRotationApps()), dispatch(fetchGameCenterApps())])
    } catch (error) {
      console.log('initGameCenterApps:', error)
    }
    logger('finish', 'preCoreDataLoad')
    dispatch(switchLoader(false))
    setTimeout(() => {
      qwebApi({ event: 'closeBootAnimation' })
    }, 0)
    logger('finish', 'showWebPage')
  }
}

// Fetch cloud apps action
export const initCloudGamesApps = ({ showLoading = false }) => {
  return async (dispatch) => {
    try {
      await dispatch(fetchCloudGameApps({ showLoading }))
    } catch (error) {
      console.log('initCloudGamesApps:', error)
    }
  }
}

// Set local perference action
export const setLocalPerference = (perference) => {
  return {
    type: types.SET_LOCAL_PERFERENCE,
    payload: perference,
  }
}
