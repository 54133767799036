import store from '@/store'
/**
 *  Get the app {actionText,actionCode,gameType,redirectCode:(AppGoogleSearch,AppDetail,AppLauncher)} according to the configuration
 * @param {object} intl Intl
 * @param {object} param1 {isInstalled:is it installed,app:app info,platforms:user-specified priority startiup way,location:execution location,oneButtonConfig:client one-button config}
 * @returns object
 */
export const getButtonText = (
  intl,
  {
    // whether id installed,default false
    isInstalled = false,
    app,
    platforms,
    location = 'common',
    // one-button config (3: user selector mode; 0: system auto selector)
    oneButtonConfig = 3,
  }
) => {
  const {
    isInstallCDN = false,
    isOpenInBrowser = false,
    button_text,
    package_name,
    game_play_preference = ['local'],
  } = app
  // whether is system auto selector
  const isAutoConfig = oneButtonConfig === 0
  // whether is google-store app
  const isGoogleIcon = package_name === 'com.android.vending'
  if (isGoogleIcon) {
    return {
      actionText: intl && intl.formatMessage({ id: 'visitStore' }),
      actionCode: 'openGooglePlay',
      gameType: 'simulator',
      redirectCode: 'AppGoogleSearch',
    }
  }
  // whether is an experience pre-registered app
  if (isOpenInBrowser) {
    return { actionText: button_text, actionCode: button_text, redirectCode: 'AppLauncher' }
  }

  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'
  // get app startup way
  const firstPlay = platforms || game_play_preference[0]
  if (firstPlay === 'cloud') {
    return {
      actionText:
        intl &&
        intl.formatMessage({
          id:
            isAutoConfig && isAppDetailAccessable
              ? location === 'appDetailPage'
                ? 'playInstantlyInBrowser'
                : 'viewDetails'
              : // 'playInstantly'
                'playOnCloud',
        }),
      actionCode: 'playOnCloud',
      gameType: 'cloud',
      redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
    }
  }
  if (firstPlay === 'local') {
    // whether is installed
    if (isInstalled) {
      return {
        actionText: intl && intl.formatMessage({ id: 'playOnAppPlayer' }),
        actionCode: 'playOnAppPlayer',
        gameType: 'simulator',
        redirectCode: 'AppLauncher',
      }
    } else {
      const isOnViewDetails = location === 'common' && isAutoConfig && isAppDetailAccessable
      if (isInstallCDN) {
        return {
          actionText:
            intl &&
            intl.formatMessage({
              id: isOnViewDetails ? 'viewDetails' : 'installInAppPlayer',
            }),
          actionCode: 'installInAppPlayer',
          gameType: 'simulator',
          redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
        }
      } else {
        return {
          actionText:
            intl &&
            intl.formatMessage({
              id: isOnViewDetails ? 'viewDetails' : 'installViaGooglePlay',
            }),
          actionCode: 'installViaGooglePlay',
          gameType: 'simulator',
          redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
        }
      }
    }
  }
  return {}
}
