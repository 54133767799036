/**
 * Show all game list module
 */

import React from 'react'
import classNames from 'classnames'
import TitleBarLayout from '@/components/layout/titleBarLayout/Index'
import GameListItem from '@/components/common/GameListItem/GameListItem'
import style from './index.module.scss'
const List = ({
  className,
  contentInnerClass,
  columns,
  customTitleRender = null,
  list,
  gameItemProps = {},
  prefix = null,
  suffix = null,
  scrollMemoryEnable,
  keyId = 'package_name',
}) => {
  return (
    <TitleBarLayout
      className={classNames([style.container, className])}
      customTitleRender={customTitleRender}
      columns={columns}
      list={list}
      keyId={keyId}
      contentInnerClass={contentInnerClass}
      prefix={prefix}
      suffix={suffix}
      scrollMemoryEnable={scrollMemoryEnable}
    >
      {(item) => {
        return (
          <GameListItem
            nameClass={`font18 bold`}
            categoryClass="font16 opaqueWhiteText"
            game={item}
            {...gameItemProps}
          />
        )
      }}
    </TitleBarLayout>
  )
}

export default React.memo(List)
